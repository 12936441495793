import { useEffect } from 'react';
import { JsonData } from '../../types';


const useFetchJsonData = (setJsonData: (jsonData: JsonData[]) => void, jsonFileName: string) => {
    useEffect(() => {
        const fetchJsonData = async () => {
            try {
                const response = await fetch(`/json/${jsonFileName}.json`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Error loading jsonData:', error);
            }
        };

        fetchJsonData();
    }, [jsonFileName, setJsonData]);
};

export default useFetchJsonData;