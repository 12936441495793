import React from 'react';
import LayoutContent from '../BaseComponents/LayoutContent';
import Modal from '../BaseComponents/Modal';
import ContactForm from '../BaseComponents/ContactForm';
import CustomLink from '../BaseComponents/CustomLink';

const socialMediaLinks = [
  { name: 'Email', url: '#' },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/in/emile-courthoud/' },
  { name: 'X / Twitter', url: 'https://x.com/emilec___' },
  { name: 'BlueSky', url: 'https://bsky.app/profile/emilecourth.bsky.social' },
];

const ConnectPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleEmailClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  return (
    <>
    <LayoutContent title="Connect">
      {socialMediaLinks.map((link, index) => (
        <CustomLink 
          children={link.name} 
          href={link.name === 'Email' ? '#' : link.url}
          key={index}
          onClick={link.name === 'Email' ? handleEmailClick : undefined}
        />
      ))}
    </LayoutContent>
      <Modal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <ContactForm />
      </Modal>
    </>
  );
};

export default ConnectPage;