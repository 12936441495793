import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHoverRing } from './useHoverRing';
import FadeInComponent from './FadeInComponent';
import useFetchJsonData from './useFetchJsonData';
import VideoComponent from './VideoWrapper';
import ExternalLinkIcon from '../../assets/link-external-01.svg';
import { JsonData, JsonFileNames } from '../../types';

const SideImageOrVideo: React.FC<{ url: string}> = ({ url }) => {
  return (
    <div className="fixed flex left-1/2 top-0 h-auto h-full w-[50vw] pr-4 pointer-events-none">
      <div className="flex w-[700px] max-w-[48vw] items-center justify-center">
        { (url.endsWith('.mp4') || url.endsWith('.mov')) ? (
          <VideoComponent
            videoSrc={url}
            className={`images-and-videos-div`}
          />
        ) : (
          <img
            src={url}
            alt="data"
            className={`images-and-videos-div`}
          />
        )}
      </div>
    </div>
  );
}

const getAssetsUrl = (jsonFileName: JsonFileNames, jsonData: JsonData) => {
  // console.log('jsonFileName', jsonFileName, jsonData);
  const url = jsonData.asset;
  if (url?.includes('../../')) {
    return url;
  } else if (jsonFileName === JsonFileNames.Projects) {
    return '../../projects/' + url;
  } else if (jsonFileName === JsonFileNames.Blogs) {
    // console.log('url', '../../blogs/' + jsonData.url + '/assets');
    return '../../blogs/' + jsonData.url.replace('/blog/', '') + '/assets/' + url;
  } else {
    console.error('JsonFileName not found');
    return '';
  }
};

const ListLinksAndImages: React.FC<{ jsonFileName: JsonFileNames, order: number }> = ({ jsonFileName, order }) => {
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState<JsonData[]>([]);

  useFetchJsonData(setJsonData, jsonFileName);

  const {
    handleMouseMove,
    handleMouseLeave,
    hoveredIndex,
    ringStyle,
    disappearing,
    containerRef,
  } = useHoverRing(jsonData);
  
  return (
    <>
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className="relative"
        ref={containerRef}
      >
        {/* Fake hover ring */}
        {ringStyle && !disappearing && (
          <div
            className={`absolute ring-[12px] bg-bg-tertiary ring-bg-tertiary rounded-md transition-all duration-300 ease-in-out pointer-events-none ${
                disappearing ? "opacity-0 scale-95" : "opacity-100 scale-100"
            }`}
            style={{
                zIndex: -1,
                top: `${ringStyle.top}px`,
                left: `${ringStyle.left}px`,
                width: `${ringStyle.width}px`,
                height: `${ringStyle.height}px`,
            }}
            />
        )}
        <FadeInComponent order={order}>
          {jsonData.map((data, index) => (
            <button
              id={`link-button-${index}`}
              key={index}
              onClick={() => {
                if (data.url.startsWith('http')) {
                  window.open(data.url, '_blank');
                } else {
                  navigate(data.url);
                }
              }}
              className="flex flex-col gap-1 rounded-md pointer-events-auto mb-8 md:leading-normal md: 
               text-left"
            >
              <div className="flex gap-2 items-center">
                <p className="text-md underline hover:no-underline">{data.title}</p>
                {data.url.startsWith('http') && (
                  <img src={ExternalLinkIcon} alt="external link" className="w-3 h-3" />
                )}
              </div>
              <p className="text-text-tertiary break-words">
                {data.date} • {data.description}
              </p>
            </button>
          ))}
        </FadeInComponent>
      </div>
      {hoveredIndex !== undefined && (
        <SideImageOrVideo url={getAssetsUrl(jsonFileName, jsonData[hoveredIndex])} />
      )}
    </>
  );
};

export default ListLinksAndImages;





