import { createContext, useContext, useEffect, ReactNode } from 'react';
import { Theme } from '../types';

const ThemeContext = createContext({
  changeTheme: (theme: Theme) => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const changeTheme = (newTheme: Theme) => {
    const root = document.documentElement;
    // Remove all theme classes
    Object.values(Theme).forEach((themeClass) => root.classList.remove(themeClass));
    // Add the new theme class
    root.classList.add(newTheme);
  };

  useEffect(() => {
    const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const systemTheme = systemThemeQuery.matches ? Theme.Dark : Theme.Light;

    // Set the initial theme
    changeTheme(systemTheme);

    // Listen for changes in the system theme
    const updateSystemTheme = (e: MediaQueryListEvent) => {
      const newSystemTheme = e.matches ? Theme.Dark : Theme.Light;
      // console.log('System theme changed to', newSystemTheme);
      changeTheme(newSystemTheme);
    };

    systemThemeQuery.addEventListener('change', updateSystemTheme);

    // Cleanup listener on component unmount
    return () => {
      systemThemeQuery.removeEventListener('change', updateSystemTheme);
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <ThemeContext.Provider value={{ changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
