import React from 'react';
import LayoutContent from '../BaseComponents/LayoutContent';
import CustomLink from '../BaseComponents/CustomLink';

const AboutPage: React.FC = () => {
  return (
    <LayoutContent title="About">
        <p className='mb-2'>I love building products and even more so when they are used and loved by people.</p>
        <p className='mb-2'>After graduating from ETH Zürich, I have spent the last 4 years working on extremely dynamic projects in the AI and space sectors.</p>
      <CustomLink children="Connect" href="/connect" className='mt-12' />
    </LayoutContent>
  );
};
export default AboutPage;