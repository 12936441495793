import React, { useState } from 'react';
import axios from 'axios';
import Button from './Button';

const TELEGRAM_API_URL = `https://api.telegram.org/bot7526226482:AAFucopSrsDqiwhqnNBADcxSCnQHyBUzxks/sendMessage`;
const CHAT_ID = '241757264';

const ContactForm: React.FC = () => {
  const [subscribed, setSubscribed] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [full_name, setFullName] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    message: false,
    full_name: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSendMessage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!full_name.trim()) {
      setErrorMessage('Please enter your name.');
      setErrors({ email: false, message: false, full_name: true });
    } else if (!email.trim()) {
      setErrorMessage('Please enter your email address.');
      setErrors({ email: true, message: false, full_name: false });
    } else if (!message.trim()) {
      setErrorMessage('Please enter a message.');
      setErrors({ email: false, message: true, full_name: false });
    } else {
      setErrors({ email: false, message: false, full_name: false });
      try {
        await axios.post(TELEGRAM_API_URL, {
          chat_id: CHAT_ID,
          text: `WEBSITE - CONTACT FORM\nName: ${full_name} (email: ${email})\nMessage:\n\n${message}`,
        });
        setErrorMessage('');
        setSuccessMessage("Thank you! I'll get back to you shortly.");
      } catch (error: any) {
        console.error('Error sending message:', error.message);
        setErrorMessage("Oops! Something went wrong. Please try again later or reach out to me directly at ecourthoud@gmail.com");
        setSuccessMessage('');
      }
    }
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <div className="relative flex flex-col gap-4">
      <p>Leave me a message or contact me at <span className='text-text-secondary'>ecourthoud@gmail.com</span>.</p>
        {subscribed ? (
          <div className="text-green-500">{successMessage}</div>
        ) : (
          <>
            <input
                value={full_name}
                type="text"
                name="FULLNAME"
                id="mce-FULLNAME"
                placeholder="Name"
                onChange={handleFullNameChange}
                className={`contact-form-input ${
                  errors.full_name ? 'border border-border-error' : 'border-border-secondary'
                }`}
            />
            <input
                value={email}
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder="Email*"
                onChange={handleEmailChange}
                className={`contact-form-input ${
                  errors.email ? 'border border-border-error' : 'border-border-secondary'
                }`}
            />
            <textarea
                value={message}
                name="MESSAGE"
                id="mce-MESSAGE"
                placeholder="A message..."
                rows={6}
                onChange={handleMessageChange}
                className={`contact-form-input h-[108px] resize-none ${
                  errors.message ? 'border border-border-error' : 'border-border-secondary'
                }`}
            />
            <Button
                text="Send"
                variant="secondary"
                size="md"
                className='w-full'
                onClick={handleSendMessage}
            />
          </>
        )}
        <div className="absolute left-[5000px]" aria-hidden="true">
            <input
              type="text"
              name="b_your-username_usX"
              tabIndex={-1}
              value=""
            />
        </div>
        {/* <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
        </div> */}
        {errorMessage && <div className="text-text-error">{errorMessage}</div>}
        {successMessage && <div className="text-text-success">{successMessage}</div>}
    </div>
  );
};

export default ContactForm;
