import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { ThemeProvider } from './styles/ThemeContext';
import './styles/colors.css';
import './styles/styles.css';
import AppRoutes from './routes/routes';

const App: React.FC = () => {
  return (
    <Router>
      <HelmetProvider>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
};

export default App;
