import React from 'react';
import LogoImg from '../../assets/logo512.png';
import { useNavigate } from 'react-router-dom';
import FadeInComponent from './FadeInComponent'; // Import the new StyledContent

interface LayoutContentProps {
    title: string;
    subtitle?: string;
    children: React.ReactNode;
    order?: number;  // Order of the child in the sequence of children to animate
    isBlog?: boolean; // If the content is a blog
}

const LayoutContent: React.FC<LayoutContentProps> = ({ title, subtitle, children, order = 1, isBlog = false }) => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <div className='layout'>
            <img 
                src={LogoImg} 
                alt='logo' 
                className='w-8 h-8 rounded-full mb-6 cursor-pointer' 
                onClick={handleLogoClick} 
            />
            <FadeInComponent className="flex flex-col" order={order}>
                <h1 className={`${isBlog && 'text-2xl'} ${subtitle ? 'mb-1' : 'mb-12'}`}>{title}</h1>
                {subtitle && <h2 className={`${isBlog && 'text-lg mt-1 mb-12'} text-text-tertiary mb-12`}>{subtitle}</h2>}
                {children}
            </FadeInComponent>
        </div>
    );
};

export default LayoutContent;
