import React, { ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import VideoComponent from '../BaseComponents/VideoWrapper';
import ColoredFrame from '../BaseComponents/ColoredFrame';
import CustomLink from '../BaseComponents/CustomLink';

const MarkdownContent: React.FC<{ content: string}> = ({ content }) => {
    const renderers = {
      h1: ({ children }: { children: ReactNode }) => (
        <h1 className="text-3xl markdown-font-highlight pt-8 pb-4">{children}</h1>
      ),
      h2: ({ children }: { children: ReactNode }) => (
        <h2 className="text-2xl markdown-font-highlight pt-3 pb-2">{children}</h2>
      ),
      h3: ({ children }: { children: ReactNode }) => (
        <h3 className="text-xl markdown-font-highlight pt-3 pb-2">{children}</h3>
      ),
      h4: ({ children }: { children: ReactNode }) => (
        <h4 className="text-lg markdown-font-highlight pt-3 pb-2">{children}</h4>
      ),
      ol: ({ children }: { children: ReactNode }) => (
        <ol className="list-decimal markdown-font-normal pl-5 pb-1">{children}</ol>
      ),
      ul: ({ children }: { children: ReactNode }) => (
        <ul className="list-disc markdown-font-normal pl-5 pb-1">{children}</ul>
      ),
      li: ({ children }: { children: ReactNode }) => (
        <li className="markdown-font-normal text-gray-800 py-1">{children}</li> // Custom style for list item
      ),
      p: ({ children }: { children: ReactNode }) => (
        <p className="markdown-font-normal pb-2">{children}</p>
      ),
      a: ({ href, children }: { href: string; children: ReactNode }) => (
        <CustomLink children={children} href={href} className="text-text-brand"/>
      ),
      strong: ({ children }: { children: ReactNode }) => (
        <strong className="markdown-font-highlight">{children}</strong>
      ),
      img: ({ src, alt }: { src: string; alt: string }) => (
        <ColoredFrame
          description={alt}
          className='my-3'
        >
          <></>
          {src.includes('.mp4') || src.includes('.mov') ? (
            <VideoComponent videoSrc={src} 
              className="max-h-[400px] rounded-md"
            />
            ) : (
              <img src={src} alt={alt} className="w-full rounded-md" />
          )}
        </ColoredFrame>
      ),
      hr: () => (
        <hr className="my-10 border border-border-primary rounded-full" />
      ), // Custom horizontal line (styled as dashed)
    };
  
    return (
      <ReactMarkdown
        children={content}
        components={renderers as any}
      />
    );
  };
  
export default MarkdownContent; 