// src/routes/AppRoutes.tsx

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from '../components/HomePage/HomePage';
import AboutPage from '../components/TextPages/AboutPage';
import ConnectPage from '../components/TextPages/ConnectPage';
import BlogPageTemplate from '../components/BlogPages/BlogTemplate';
import BlogsPage from '../components/BlogPages/BlogsPage';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Redirects */}
      <Route path="*" element={<Navigate to="/" />} />
      
      {/* Website routes */}
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/connect" element={<ConnectPage />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/blog/:slug" element={<BlogPageTemplate />} />
    </Routes>
  );
};

export default AppRoutes;
