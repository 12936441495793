import React from 'react';
import LayoutContent from '../BaseComponents/LayoutContent';
import ListLinksAndImages from '../BaseComponents/ListLinksAndImages';
import { JsonFileNames } from '../../types';

const HomePage: React.FC = () => {
  return (
    <LayoutContent title="Blog">
      <ListLinksAndImages jsonFileName={JsonFileNames.Blogs} order={3}/>
    </LayoutContent>
  );
};
export default HomePage;