import React, { useEffect, useRef } from 'react';

interface VideoComponentProps {
    videoSrc: string;
    className?: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ videoSrc, className }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    // console.log('className', className);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [videoSrc]);

    return (
        <div className='flex relative'>
            <video
                ref={videoRef}
                className={`${className}`}
                loop
                autoPlay
                muted
            >
                <source src={videoSrc} type="video/mp4" />
            </video>
        </div>
    );
};

export default VideoComponent;
