import { useState, useRef, useEffect } from "react";

interface RingStyle {
  left: number;
  top: number;
  width: number;
  height: number;
}

export const useHoverRing = (links: any[]) => {
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
  const [ringStyle, setRingStyle] = useState<RingStyle | null>(null); 
  const [disappearing, setDisappearing] = useState(false); 
  const containerRef = useRef<HTMLDivElement | null>(null);

  const leaveTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseMove = (e: React.MouseEvent) => {
    setMousePos({ x: e.clientX, y: e.clientY });
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
      leaveTimeout.current = null;
    }
    setDisappearing(false); // Reset disappearing when mouse re-enters
  };

const handleMouseLeave = () => {
    setDisappearing(false);
    leaveTimeout.current = setTimeout(() => {
        setHoveredIndex(undefined); // Ensure hoveredIndex is cleared
        setRingStyle(null); // Remove ringStyle to keep the ring hidden
    }, 800); // Match the transition duration
};

  useEffect(() => {
    const getHoveredButtonIndex = () => {
      let closestButtonIndex = -1;
      let closestButtonRect: DOMRect | null = null;

      links.forEach((_, index) => {
        const button = document.getElementById(`link-button-${index}`);
        if (button) {
          const rect = button.getBoundingClientRect();
          if (
            mousePos.x >= rect.left &&
            mousePos.x <= rect.right &&
            mousePos.y >= rect.top &&
            mousePos.y <= rect.bottom
          ) {
            closestButtonIndex = index;
            closestButtonRect = rect;
          }
        }
      });

      if (closestButtonRect) {
        closestButtonRect = closestButtonRect as DOMRect;
        setRingStyle({
          left: closestButtonRect.left - containerRef.current!.getBoundingClientRect().left,
          top: closestButtonRect.top - containerRef.current!.getBoundingClientRect().top,
          width: closestButtonRect.width,
          height: closestButtonRect.height,
        });
      }

      return closestButtonIndex;
    };

    const containerRect = containerRef.current?.getBoundingClientRect();
    if (
      containerRect &&
      (mousePos.x < containerRect.left ||
        mousePos.x > containerRect.right ||
        mousePos.y < containerRect.top ||
        mousePos.y > containerRect.bottom)
    ) {
      setHoveredIndex(undefined);
    } else {
      const index = getHoveredButtonIndex();
      console.log('index', index);
      if (index !== -1) {
        setHoveredIndex(index);
      }
    }
  }, [mousePos, links]);

  useEffect(() => {
    return () => {
      if (leaveTimeout.current) {
        clearTimeout(leaveTimeout.current);
      }
    };
  }, []);

  return {
    handleMouseMove,
    handleMouseLeave,
    hoveredIndex,
    ringStyle,
    disappearing,
    containerRef,
  };
};
