import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

export interface CustomLinkProps {
    children: string | ReactNode;   
    href: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    className?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ children, href, onClick, className }) => {
    const navigate = useNavigate();
    return (
        <a
            href={href as string}
            target="_blank"
            rel="noreferrer"
            className={`mb-6 underline ${className}`}
            onClick={(e) => {
                e.preventDefault();
                if (onClick) {
                    onClick(e);
                } else if (href.startsWith('https')) {
                  window.open(href, '_blank');
                } else {
                    navigate(href);
                }
              }}
        >
            {children}
        </a>
    );
};

export default CustomLink;
