import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import LayoutContent from '../BaseComponents/LayoutContent';
import useFetchJsonData from '../BaseComponents/useFetchJsonData';
import { JsonData, JsonFileNames } from '../../types';
import MarkdownContent from './MarkdownContent';


const BlogTemplate: React.FC = () => {
  const [content, setContent] = useState<string>('');
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState<JsonData[]>([]);
  const [blog, setBlog] = useState<JsonData | null>(null);

  useFetchJsonData(setBlogData, JsonFileNames.Blogs);

  // console.log('blogData', blogData);

  useEffect(() => {
    if (blogData?.length > 0) {
      const url = window.location.pathname;
      
      // Set as blog the blogData with same url as the url parameter
      const currentBlog = blogData.find((blog) => blog.url === url);
      if (!currentBlog) {
        console.error('Blog not found');
        navigate('/'); // Redirect to home page if the blog is not found
      }
      setBlog(currentBlog as JsonData);
      fetch(`/blogs/${url.replace('/blog/', '')}/text.md`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch article content');
          // } else {
          //   console.log('response', response);
          }
          return response.text();
        })
        .then(setContent)
        .catch(() => navigate('/'));
      }
  }, [blogData, navigate]);


  return blog && (
    <LayoutContent title={blog.title} subtitle={blog.description} isBlog={true}>
      <Helmet>
        <meta name="description" content={blog.description} />
      </Helmet>
      {
        <img 
          src={'/blogs' + window.location.pathname.replace('blog/', '') + '/assets/cover.png'} alt={blog.title}
          className='blog-cover'
        />
      }
      {/* TODO: implement support for different types of covers
      
      {blog.asset && (blog.asset.endsWith('.mp4') || blog.asset.endsWith('.mov')) ? (
        <VideoComponent
          videoSrc={'/blogs' + window.location.pathname.replace('blog/', '') + '/assets/' + blog.asset}
          className='blog-cover'
        />
      ) : blog.asset && (
        <img 
          src={'/blogs' + window.location.pathname.replace('blog/', '') + '/assets/' + blog.asset} alt={blog.title}
          className='blog-cover'
        />
      )} 
       
       */}
      <MarkdownContent content={content} />
    </LayoutContent>
  );
};

export default BlogTemplate;
