/* Types */

export enum Theme {
  Light = 'light',
  Dark = 'dark',
  Dusk = 'dark-dusk'
}

export interface JsonData {
  title: string;
  author?: string;
  date: string;
  description: string;
  url: string;
  asset?: string;
}

export enum JsonFileNames {
  Projects = 'projects',
  Blogs = 'blogs',
}

export type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'menuDesktop' | 'menuMobile';
