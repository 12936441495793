import React from 'react';
import CloseIcon from '../../assets/x-close.svg';
import Button from './Button';

export interface ModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  if (!isOpen) return null;

  // Handles closing the modal when clicking outside
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  return (
    <div 
      className="overlay-page fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleOverlayClick} // Add click handler
    >
      <div
        className={`relative bg-bg-primary rounded-xl shadow-xl overflow-hidden w-full sm:w-[440px]`}
      >
        <div className='flex flex-col flex-1 gap-2 text-left p-6 h-full'>
          {/* Modal Header */}
          <div className="flex justify-between mb-4 z-10">
            <div className="flex flex-col gap-2 flex-1">
              <h2 className="text-lg">Contact</h2>
            </div>
            <Button 
              variant='tertiary'
              iconOnly
              onClick={() => setIsOpen(false)}
              leadingIcon={CloseIcon}
            />
          </div>
          {/* Modal Body */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
